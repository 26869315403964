import { Divider, TextField, Typography, useTheme } from '@mui/material';
import {
  ErrorCircle16Regular,
  PeopleSwap20Regular,
} from '@fluentui/react-icons';
import { useState } from 'react';
import { DetailsRow } from '../DetailsRow';
import { SelectApprover } from '../SelectApprover';
import { Button } from '../button';
import { UserChip, DisposalRequestResponseStatusChip } from '../chip';
import {
  Approver,
  ApproversList,
  DisposalRequest,
  DisposalRequestResponseStatus,
  User,
} from '../../types';
import { Box } from '../Box';
import { CustomActionModal } from './CustomActionModal';
import { EditApprovers } from '../EditApprovers';

export type DisposalRequestDelegationDialogProps = {
  title?: string;
  request?: DisposalRequest | undefined;
  open?: boolean;
  approvers?: ApproversList | undefined;
  user?: User;
  onClose?: () => void;
  onAction?: (comment: string, delegateToUserId: string) => void;
};

export const DisposalRequestDelegationDialog = ({
  title = 'Delegate',
  request,
  open,
  approvers,
  user,
  onClose,
  onAction,
}: DisposalRequestDelegationDialogProps) => {
  const theme = useTheme();

  const [comment, setComment] = useState('');
  const [delegateToUser, setDelegateToUser] = useState('');
  const [isApproversListValid, setIsApproversListValid] = useState(true);
  const [selectedApprovers, setSelectedApprovers] = useState([]);

  const handleUserSelection = (newApprover: Approver) => {
    setDelegateToUser(newApprover.ID);
  };

  return (
    <CustomActionModal
      title={title}
      onClose={() => {
        setDelegateToUser('');
        setComment('');
        onClose();
      }}
      open={open}
      icon={<PeopleSwap20Regular style={{ marginRight: '0.75rem' }} />}
      actionButton={
        <Button
          variant='contained'
          onClick={() => {
            onAction(comment, delegateToUser);
            setDelegateToUser('');
            setComment('');
          }}
          disabled={delegateToUser == ''}
        >
          Delegate
        </Button>
      }
    >
      <DetailsRow
        label='Request Name'
        data={request?.Name}
        size='small'
        alignData
      />
      <DetailsRow
        label='Disposal Action'
        data={request?.DisposalAction}
        size='small'
        alignData
      />

      <DetailsRow
        label='Response'
        data={
          <DisposalRequestResponseStatusChip
            status={DisposalRequestResponseStatus.Delegated}
          />
        }
        size='small'
        alignData
      />

      <DetailsRow
        label='Response By'
        data={<UserChip user={user} />}
        size='small'
        alignData
      />
      <Divider style={{ margin: '2rem 0 2rem 0' }} />
      <Typography variant='body1'>Approver</Typography>

      <EditApprovers
        approvers={approvers}
        selectedApprovers={selectedApprovers}
        setSelectedApprovers={setSelectedApprovers}
        isApproversListValid={isApproversListValid}
        setIsApproversListValid={setIsApproversListValid}
        onSelect={handleUserSelection}
        singleApprovalSelect={true}
      />
      {!isApproversListValid && (
        <Box
          background='none'
          color='red'
          direction='row'
          style={{
            marginTop: '0.75rem',
          }}
        >
          <ErrorCircle16Regular
            color={theme.palette.warning.main}
            style={{
              marginRight: '.3rem',
            }}
          />
          <Typography variant='body3' color={theme.palette.warning.main}>
            Please specify approvers
          </Typography>
        </Box>
      )}

      <Typography variant='body1' style={{ marginTop: '1.5rem' }}>
        Comment
      </Typography>
      <TextField
        sx={{
          marginTop: '0.5rem',
        }}
        fullWidth
        maxRows={5}
        minRows={5}
        multiline
        onChange={(event) => setComment(event.target.value)}
        value={comment}
      />
    </CustomActionModal>
  );
};
export default DisposalRequestDelegationDialog;
